@charset "utf-8";

/* 共通 */
*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* モーダル画面時の背景 */
.overlay {
  position:fixed;
  z-index: 1201;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay-dark {
  position:absolute;
  z-index: 1201;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
  
/* fullscreen */
.fullscreen {
  position:absolute;
  z-index: 1201;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

/* リンク風のボタン */
button.link-style-btn{
  cursor: pointer;
  border: none;
  background: none;
/*  font-size: inherit; */
  color: #0033cc;
}
button.link-style-btn:hover{
  text-decoration: underline;
  color: #002080;
}

/* ノードツリー */
.node-tree {
  display: flex;
  position: relative;
  width: 100%;
}
.node-tree label {
  background-color: #c9e9f1;
  width: 100vw;
  border: 3px outset #c9e9f1;
  display: flex;
  align-items: center;
}
.node-tree label:hover {
  background-color: #aaddff;
}
.node-tree label input[type=checkbox] {
  display: none;
}
.node-tree label span {
  display: inline-block;
  width: calc(100% - 55px);
}

/* ドロップダウンメニュー */
.dropdown-menu {
  list-style-type: none;
  border: solid 1.5px #000;
  box-sizing: border-box;
  background-color: #fff;
  position: absolute;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -25px;
  right: 0%;
  border: 12px solid transparent;
  border-bottom: 13px solid #FFF;
  z-index: 2;
}
.dropdown-menu::after {
  content: "";
  position: absolute;
  top: -28px;
  right: -1%;
  border: 13px solid transparent;
  border-bottom: 14px solid #000;
  z-index: 1;
}
.dropdown-menu li {
  margin: 2px 5px 2px 5px;
  padding-left: 3px;
}
.dropdown-menu li:first-child {
  margin-top: 4px;
}
.dropdown-menu li:last-child {
  margin-bottom: 4px;
}
.dropdown-menu li:hover {
  background-color: #aaddff;
  font-weight: bold;
}




